<template>
  <div>
  </div>
</template>

<script>
export default {
  created() {
    this.$router.push('userCourses');
  }
}
</script>

<style lang="scss" scoped></style>